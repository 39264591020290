import React, { useState } from "react";
import Banner from "react-js-banner";
import logo from "../../images/etc_banner_1920x504.jpg";
import { TextField, IconButton } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
function BannerWithTitle() {
  const [text, setText] = useState("");

  const onChange = (evt) => setText(evt.target.value);

  return (
    <React.Fragment>
      <div className="banner-wrap">
        <Banner
          className="banner-image"
          title="Entdecken Sie unser Seminarangebot"
          image={logo}
          imageClass="banner-logo"
        />
        <form action="/courses" method="get">
          <TextField
            name="search_query"
            className="banner-search"
            id="standard-bare"
            variant="outlined"
            onChange={onChange}
            value={text}
            type="search"
            placeholder="Search for a course"
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <SearchOutlined className="search-icon" />
                </IconButton>
              ),
            }}
          />
        </form>
      </div>
    </React.Fragment>
  );
}

export default BannerWithTitle;
