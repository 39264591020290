import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Banner from "../components/Main/Banner";
import axios from "axios";
import MediaCard from "../components/Main/MediaCard";
import CookieConsent from "react-cookie-consent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CircularProgress,
  Container,
  MenuItem,
  Select,
  FormControl,
  makeStyles,
  Divider,
  ListItem,
} from "@material-ui/core";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CategoryItems from "../category.json";

const categoryToFilter = [
  "Microsoft office",
  "Microsoft Office Updates",
  "security",
  "Office365",
  "Microsoft Office Specialist Zertifizierung",
  "Windows 10",
  "Simply Azure Academy",
  "Cloud Fundamentals",
  "Technical Skills for Business",
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const StyledTabArea = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "menu content";
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  .react-tabs__tab {
    display: flex;
    border: none;
    :hover {
      background: #77bd7e;
      color: #fff;
    }
  }
  .react-tabs__tab--selected {
    color: #77bd7e !important;
    background-color: #fff !important;
    font-weight: 700;
    @media screen and (max-width: 991px) {
      color: #fff !important;
      background-color: #77bd7e !important;
    }
  }
`;
const StyledMenuArea = styled.div`
  grid-area: menu;
  margin-right: -40px;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
`;
const StyledContentArea = styled.div`
  grid-area: content;
  padding-left: 40px;
  @media screen and (max-width: 478px) {
    padding-left: 0;
  }
`;

const Home = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const [selectedPlan, setSelectedPlan] = React.useState({});

  const [msOffice, setMsOffice] = useState([]);
  const [filteredMsOffice, setFilteredMsOffice] = useState(msOffice);

  const [msOUpdates, setMsOUpdates] = useState([]);
  const [filteredmsOUpdates, setFilterdMsOUpdates] = useState(msOUpdates);

  const [security, setSecurity] = useState([]);
  const [filterSecurity, setfilterSecurity] = useState(security);

  const [MO365, setMO365] = useState([]);
  const [filterMO365, setfilterMO365] = useState(MO365);

  const [zertifizierung, setZertifizierung] = useState([]);
  const [filterZertifizierung, setfilterZertifizierung] =
    useState(zertifizierung);

  const [Windows10, setWindows10] = useState([]);
  const [filterWindows10, setfilterWindows10] = useState(Windows10);

  const [Azure, setAzure] = useState([]);
  const [filterAzure, setfilterAzure] = useState(Azure);

  const [Cloud, setCloud] = useState([]);
  const [filterCloud, setfilterCloud] = useState(Cloud);

  const [Business, setBusiness] = useState([]);
  const [filterBusiness, setfilterBusiness] = useState(Business);

  const [open, setOpen] = React.useState(false);

  const update_data = async () => {
    const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
    let BASE_URL_PUBLIC = "";

    /* offline */
    if (LOCAL_DOMAINS.includes(window.location.hostname)) {
      BASE_URL_PUBLIC = "https://staging-etc.abzt.de/api/courses/v1/courses/";
    } else {
      /* online || production && staging */
      BASE_URL_PUBLIC = "/api/courses/v1/courses/";
    }
    setIsLoading(true);

    Promise.all(
      categoryToFilter.map((name) =>
        axios(`${BASE_URL_PUBLIC}?page_size=100&category=${name}`)
      )
    ).then((res) => {
      setMsOffice(res[0].data.results);
      setFilteredMsOffice(res[0].data.results);
      setMsOUpdates(res[1].data.results);
      setFilterdMsOUpdates(res[1].data.results);
      setSecurity(res[2].data.results);
      setfilterSecurity(res[2].data.results);
      setMO365(res[3].data.results);
      setfilterMO365(res[3].data.results);
      setZertifizierung(res[4].data.results);
      setfilterZertifizierung(res[4].data.results);
      setWindows10(res[5].data.results);
      setfilterWindows10(res[5].data.results);
      setAzure(res[6].data.results);
      setfilterAzure(res[6].data.results);
      setCloud(res[7].data.results);
      setfilterCloud(res[7].data.results);
      setBusiness(res[8].data.results);
      setfilterBusiness(res[8].data.results);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    update_data();
  }, []);

  const settings = {
    slidesToScroll: 3,
    slidesToShow: 3,
    centerMode: true,
    infinite: true,
    center: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          center: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          center: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: false,
        },
      },
    ],
  };

  const handleChange = (event) => {
    setSelectedPlan(event.name);
    let resultMS = [];
    let resultMSU = [];
    let resultSecurity = [];
    let resultMSO = [];
    let RZertifizierung = [];
    let resultWindows = [];
    let resultAzure = [];
    let resultCloud = [];
    let resultBusiness = [];

    resultMS = msOffice.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultMSU = msOUpdates.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultSecurity = security.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultMSO = MO365.filter((data) => {
      return data.categories.includes(event.name);
    });

    RZertifizierung = zertifizierung.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultWindows = Windows10.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultAzure = Azure.filter((data) => {
      return data.categories.includes(event.name);
    });

    resultCloud = Cloud.filter((data) => {
      return data.categories.includes(event.name);
    });
    resultBusiness = Business.filter((data) => {
      return data.categories.includes(event.name);
    });
    setFilteredMsOffice(resultMS);
    setFilterdMsOUpdates(resultMSU);
    setfilterSecurity(resultSecurity);
    setfilterMO365(resultMSO);
    setfilterZertifizierung(RZertifizierung);
    setfilterWindows10(resultWindows);
    setfilterAzure(resultAzure);
    setfilterCloud(resultCloud);
    setfilterBusiness(resultBusiness);
  };

  const closeChange = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Layout>
      <Container maxWidth={"xl"} disableGutters={true}>
        {" "}
        <Banner />
        <Container maxWidth="xl">
          <FormControl
            variant="outlined"
            className={`${classes.formControl} select-input`}
          >
            <Select
              value={selectedPlan}
              displayEmpty
              open={open}
              onOpen={handleOpen}
              onClose={closeChange}
              renderValue={selectedPlan === "" ? undefined : () => "Kategorien"}
            >
              <Tabs>
                <StyledTabArea>
                  <StyledMenuArea className="tabs-list">
                    <TabList className="tabs">
                      <Tab>
                        {CategoryItems[0].name} <KeyboardArrowRightIcon />
                      </Tab>
                      <Divider />
                      <Tab>
                        {CategoryItems[1].name} <KeyboardArrowRightIcon />
                      </Tab>
                      <Divider />
                    </TabList>
                  </StyledMenuArea>
                  <StyledContentArea>
                    <TabPanel>
                      {CategoryItems[0].models.map((i) => {
                        return (
                          <div key={i.id} onClick={closeChange}>
                            <MenuItem onClick={() => handleChange(i)}>
                              {i.label}
                            </MenuItem>
                          </div>
                        );
                      })}
                    </TabPanel>
                    <TabPanel>
                      {CategoryItems[1].models.map((i) => {
                        return (
                          <div key={i.id} onClick={closeChange}>
                            <ListItem
                              value={i.value}
                              onClick={() => handleChange(i)}
                            >
                              {i.label}
                            </ListItem>
                          </div>
                        );
                      })}
                    </TabPanel>
                  </StyledContentArea>
                </StyledTabArea>
              </Tabs>
            </Select>
          </FormControl>
        </Container>
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filteredMsOffice && filteredMsOffice.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[0].label}
              </h1>
              <Slider {...settings}>
                {filteredMsOffice.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filteredmsOUpdates && filteredmsOUpdates.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[1].label}
              </h1>
              <Slider {...settings}>
                {filteredmsOUpdates.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterSecurity && filterSecurity.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[2].label}
              </h1>
              <Slider {...settings}>
                {filterSecurity.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterMO365 && filterMO365.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[3].label}
              </h1>
              <Slider {...settings}>
                {filterMO365.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterZertifizierung && filterZertifizierung.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[4].label}
              </h1>
              <Slider {...settings}>
                {filterZertifizierung.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterWindows10 && filterWindows10.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[0].name} | {CategoryItems[0].models[5].label}
              </h1>
              <Slider {...settings}>
                {filterWindows10.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterAzure && filterAzure.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[1].name} | {CategoryItems[1].models[0].label}
              </h1>
              <Slider {...settings}>
                {filterAzure.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterCloud && filterCloud.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[1].name} | {CategoryItems[1].models[1].label}
              </h1>
              <Slider {...settings}>
                {filterCloud.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        {isLoading ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : filterBusiness && filterBusiness.length > 0 ? (
          <div className="slider-container just_sorted" id="onlineCourse">
            <Container maxWidth="xl">
              <h1 className="course-name">
                {CategoryItems[1].name} | {CategoryItems[1].models[2].label}
              </h1>
              <Slider {...settings}>
                {filterBusiness.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </Container>
          </div>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          cookieName="hasconsent"
          style={{ background: "#444" }}
          expires={999}
        >
          <p>
            Wir nutzen nur technisch notwendige Cookies auf unserer Website.
            Nähere Hinweise finden Sie in der{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.etc.at/datenschutz/"
            >
              Datenschutzerklärung
            </a>
          
          </p>
        </CookieConsent>
      </Container>
    </Layout>
  );
};

export default Home;
