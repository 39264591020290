import React, { useState, useRef } from "react";
import classnames from "classnames";
import { useIntersection } from "./intersectionObserver";
import "./imageRenderer.scss";

const ImageRenderer = ({ url, thumb, width, height, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div
      className="image-container"
      ref={imgRef}
      style={{
        paddingBottom: `56%`,
        width: "100%",
      }}
    >
      {isInView && (
        <>
          <img
            className={classnames("image", "thumb", {
              // eslint-disable-next-line
              ["isLoaded"]: !!isLoaded,
            })}
            src={thumb}
            alt={alt}
          />
          <img
            className={classnames("image", {
              // eslint-disable-next-line
              ["isLoaded"]: !!isLoaded,
            })}
            src={url}
            alt={alt}
            onLoad={handleOnLoad}
          />
        </>
      )}
    </div>
  );
};

export default ImageRenderer;
