import * as React from "react";

import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  CardActions,
  Link,
} from "@material-ui/core";
import ImageRenderer from "./ImageRenderer";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    borderRadius: "0",
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    position: "relative",
  },
  cardContent: {
    flexGrow: 1,
  },
  meinGrid: {
    marginBottom: "150px",
    "& >h1, >h5": {
      textAlign: "center",
      paddingBottom: 30,
    },
  },
  cardWrapper: {
    border: "1px solid rgba(0,0,0,0.06)",
    background: "#fff",
  },
  hover: {
    "&:hover": {
      textDecoration: "none",
    },

    "&*": {
      textDecoration: "none",
    },
  },
  head: {
    textAlign: "center",
  },
  more: {
    width: "calc(100% - 32px)",
    color: "#fff",
    boxShadow: "none",
    backgroundColor: "#1f3685",
    border: "1px solid #1f3685",
    backgroundImage: "none",
    textShadow: "none",
    padding: "10px 16px",
    margin: 0,
    marginLeft: "auto !important",
    marginRight: "auto",
    "&:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled)": {
      backgroundColor: "#fff",
      border: "1px solid #1f3685",
      color: "#1f3685",
      backgroundImage: "none",
      boxShadow: "none",
      textDecoration: "none",
    },
  },
  action: {
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: 20,
  },
  text: {
    paddingLeft: 16,
    paddingBottom: 15,
  },
}));

const MediaCard = ({ courseInfo }) => {
  const classes = useStyles();
  const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
  let BASE_URL_PUBLIC = "";

  /* offline */
  if (LOCAL_DOMAINS.includes(window.location.hostname)) {
    BASE_URL_PUBLIC = "http://staging-etc.abzt.de/courses/";
  } else {
    /* online || production && staging */
    BASE_URL_PUBLIC = "/courses/";
  }

  const date = (data) => {
    let unformatData = new Date(data);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return unformatData.toLocaleDateString("de-DE", options);
  };

  const truncate = (str) => {
    if (str) {
      return str.length > 170 ? str.substring(0, 170) + "..." : str;
    }
  };

  return (
    <Link
      href={BASE_URL_PUBLIC + courseInfo.id + "/about"}
      className="link-card"
    >
      <Card className={classes.card}>
        <ImageRenderer
          url={courseInfo.media.image.large}
          thumb={courseInfo.media.image.large}
          width={10}
          height={10}
          alt={courseInfo.name}
        />

        <div className={classes.cardWrapper}>
          <CardContent className={`${classes.cardContent} course-content`}>
            <Typography
              gutterBottom
              variant="caption"
              className="course-org-card"
            >
              {courseInfo.org}
            </Typography>
            <h2 className="course-title-card">{courseInfo.name}</h2>
            <p
              className="course-description"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {truncate(courseInfo.short_description)}
            </p>
          </CardContent>
          <CardActions className={classes.action}>
            <Typography className={`${classes.text} course-date-card`}>
              Startet - {date(courseInfo.start)}
            </Typography>
            <Link
              className={classes.more}
              component="button"
              variant="body2"
              href={BASE_URL_PUBLIC + courseInfo.id + "/about"}
            >
              Mehr
            </Link>
          </CardActions>
        </div>
      </Card>
    </Link>
  );
};

export default MediaCard;
