import React from "react";

import { Grid, Container, Divider } from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CallIcon from "@material-ui/icons/Call";

import Logo from "../../images/logo.png";

import "./App.scss";

const firstCol = [
  {
    label: "Online Seminarkatalog",
    href: "https://www.etc.at/seminarkatalog/",
  },
  {
    label: "Öffentliche Instructor Led Trainings (ILT)",
    href: "https://www.etc.at/seminarkatalog/oeffentliche-trainings/",
  },
  {
    label: "Firmenseminare",
    href: "https://www.etc.at/services/etc-firmenschulung-so-individuell-wie-ihre-anforderung/",
  },
  { label: "Virtual Classroom ETC Live", href: "https://www.etc.at/live/" },
  { label: "Zertifizierungen", href: "https://www.etc.at/zertifizierungen/" },
  {
    label:
      "Internationale Training-Projekte mit LLPA Leading Learning Partner Association",
    href: "https://www.etc.at/unternehmen/llpa-leading-learning-partner-association/",
  },
];

const secondCol = [
  {
    label: "ETC Termin-Garantie",
    href: "https://www.etc.at/services/termingarantie/",
  },
  {
    label: "ETC Wissensgarantie",
    href: "https://www.etc.at/services/etc-wissensgarantie/",
  },
  {
    label: "Rabatte und Aktionen",
    href: "https://www.etc.at/services/rabatte-und-aktionen/",
  },
  {
    label: "Microsoft SATV – Software Assurance Training Voucher",
    href: "https://www.etc.at/satv-software-assurrance-training-voucher/",
  },
  {
    label: "Teilnehmeraussagen",
    href: "https://www.etc.at/unternehmen/teilnehmeraussagen/",
  },
  { label: "Veranstaltungen und Event", href: "https://www.etc.at/event/" },
  { label: "ETC FAQ", href: "https://www.etc.at/faqs/" },
];

const thirdCol = [
  {
    label: "Kontakt und Rückruf- Service",
    href: "https://www.etc.at/services/rueckruf-service/",
  },
  {
    label: "Examen Anmeldung, Öffnungszeiten",
    href: "https://www.etc.at/zertifizierungen/testing-center/",
  },
  {
    label: "ETC Seminarzentrum Standorte",
    href: "https://www.etc.at/unternehmen/standorte/",
  },
  { label: "Impressum", href: "https://www.etc.at/unternehmen/impressum/" },
  {
    label: "Allgemeine Geschäftsbedingungen (AGB)",
    href: "https://www.etc.at/agb/",
  },
];

const Footer = () => {
  return (
    <Container maxWidth={"xl"} disableGutters>
      <footer className="App">
        <Grid container className="grid-footer">
          <Grid item xs={12} sm={6} md={3}>
            <h1>Seminarangebot</h1>
            <ul className="footer-list">
              {firstCol.map((item) => {
                return (
                  <li key={item.label}>
                    <a href={item.href}>{item.label}</a>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <h1>Service</h1>
            <ul className="footer-list">
              {secondCol.map((item) => {
                return (
                  <li key={item.label}>
                    <a href={item.href}>{item.label}</a>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <h1>Kontakt</h1>
            <ul className="footer-list">
              {thirdCol.map((item) => {
                return (
                  <li key={item.label}>
                    <a href={item.href}>{item.label}</a>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <h1>Über ETC</h1>
            <p>
              ETC - Enterprise Training Center ist Österreichs Nr. 1 Anbieter
              für IT Seminare mit Standorten in Wien, Graz und Attnang-Puchheim!
              Hochwertige Hersteller-Zertifizierungen (wie z.B. als einziger
              Microsoft GOLD Learning Partner in Österreich) und
              Qualitäts-Zertifizierungen nach ISO9001 und ISO29990 gewährleisten
              bei unseren Trainings den höchsten Qualitäts- und Service-Level
              für unsere Kunden!
            </p>
          </Grid>

          <Divider />
          <Grid item md={3} xs={12}>
            <p>Modecenterstrasse 22, 1030 Wien</p>
          </Grid>
          <Grid item md={3} xs={12}>
            <p>Info@etc.at</p>
          </Grid>
          <Grid item md={6} xs={12}>
            <ul className="flex-items">
              <li>
                <a href="https://www.instagram.com/etc_enterprise_trainingcenter/">
                  Instagram
                </a>
                <ArrowForwardIcon />
              </li>
              <li>
                <a href="https://www.linkedin.com/company/etc---enterprise-training-center/">
                  Linkedin
                </a>
                <ArrowForwardIcon />
              </li>
              <li>
                <a href="https://www.xing.com/pages/etc-enterprisetrainingcenter">
                  Xing
                </a>
                <ArrowForwardIcon />
              </li>
              <li>
                <a href="https://www.facebook.com/EnterpriseTrainingCenter">
                  Facebook
                </a>
                <ArrowForwardIcon />
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            <div className="logo-wrapper">
              <img className="footer-logo" src={Logo} alt="logo" />

              <div className="tel">
                <CallIcon />
                <a href="tel:004315331777">+43 1533 1777</a>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <ul className="flex-privacy_policy">
              <li>
                <a href="https://www.etc.at/agb">AGB</a>
              </li>
              <li>
                <a href="https://www.etc.at/datenschutz">Datenschutz</a>
              </li>
              <li>
                <a href="https://www.etc.at/unternehmen/impressum/">
                  Impressum
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </footer>
    </Container>
  );
};

export default Footer;
