import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Divider, Link, Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";
import useCookie from "./useCookies";
import { Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./fontawesome.css";

const drawerWidth = "75%";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  profile: {
    borderBottom: "1px solid #e7e7e7",
  },
  menuButton: {
    color: "#77bd7e",
    border: "1px solid #77bd7e",
    padding: "10px 20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#77bd7e",
      color: "#fff",
      border: "1px solid #77bd7e",
    },
  },
  menuButtonLogin: {
    marginRight: 6,
    backgroundColor: "#77bd7e",
    color: "#fff",
    border: "1px solid #77bd7e",
    padding: "10px 20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#77bd7e",
      border: "1px solid #77bd7e",
    },
  },

  menuModalButton: {
    marginRight: 6,
    backgroundColor: "#50963c",
    color: "#fff",
    border: "1px solid #50963c",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#50963c",
      border: "1px solid #50963c",
    },
  },
  title: {
    fontSize: "1rem",
  },
  header: {
    backgroundColor: "#fff",
    maxWidth: 1920,
    right: "auto",
  },
  lasttitle: {
    flexGrow: 1,
    color: "#000",
    fontSize: "1rem",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
    "& ul": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  desktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButtonTop: {
    justifyContent: "flex-end",
    marginRight: 15,
  },
  closeicon: {
    float: "right",
    marginRight: 15,
    fontSize: "2rem",
  },
  buttonList: {
    width: "100%",
  },
  drawer: {
    "& a": {
      color: "#50963c",
    },
    "& button": {
      marginRight: theme.spacing(2),
      backgroundColor: "#50963c",
      color: "#fff",
      border: "1px solid #50963c",

      "&:hover": {
        backgroundColor: "#fff",
        color: "#50963c",
        border: "1px solid #50963c",
      },
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  const [toggleState, setToggleState] = useState("hidden");
  const [toggleMenu, setToggleMenu] = useState("");

  const [isLoged, setIsLoged] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [modalEl, setModalEl] = React.useState(false);

  const cookie = useCookie("edxloggedin");
  const cookiesInfo = useCookie("edx-user-info");
  if (typeof cookiesInfo !== "undefined") {
    const replacedbad = cookiesInfo.replace(/\\054/g, ",");
    const cookiesJson = JSON.parse(JSON.parse(replacedbad));
    var learner_profile = cookiesJson.header_urls.learner_profile;
    var account_settings = cookiesJson.header_urls.account_settings;
    var logout = cookiesJson.header_urls.logout;
  }

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const openModal = (event) => {
    setAnchorEl(event.currentTarget);
    setModalEl(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClose = () => {
    setModalEl(false);
  };

  const toggle = () => {
    setToggleMenu(toggleMenu === "" ? "open" : "");
    setToggleState(toggleState === "hidden" ? "" : "hidden");
  };

  React.useEffect(() => {

    const interval = setInterval(() => {
      if (cookie === "true") {
        setIsLoged(true);
      }
    }, 10000);
    return () => clearInterval(interval);
   
  }, [cookie]);

  const drawer = (
    <div className={classes.drawer}>
      {!isLoged ? (
        <React.Fragment>
          <MenuItem
            onClick={handleClose}
            className={`${classes.profile} list-menu-item `}
          >
            <Link
              className={`${classes.title} menu-item mobile  menu-item-button not-authenticated`}
              href={"/register"}
            >
              Registrieren
            </Link>
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            className={`${classes.profile} list-menu-item `}
          >
            {" "}
            <Link
              className={`${classes.title} menu-item mobile  menu-item-button not-authenticated`}
              href={"/login"}
            >
              Anmelden{" "}
            </Link>
          </MenuItem>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MenuItem
            onClose={closeMenu}
            className={`${classes.profile} list-menu-item `}
          >
            <Link
              href="/dashboard"
              className={`${classes.title} menu-item mobile menu-item-button`}
            >
              {" "}
              Meine Kurse
            </Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem className={`${classes.profile} list-menu-item `}>
            <Link
              href={learner_profile}
              className={`${classes.title} menu-item mobile menu-item-button`}
            >
              Profil
            </Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem
            onClose={closeMenu}
            className={`${classes.profile} list-menu-item mobile`}
          >
            <Link
              href={account_settings}
              className={`${classes.title} menu-item mobile menu-item-button`}
            >
              Benutzerkonto
            </Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem
            onClose={closeMenu}
            className={`${classes.profile} list-menu-item mobile`}
          >
            <Link
              href={logout}
              className={`${classes.title} menu-item mobile menu-item-button`}
            >
              Abmelden
            </Link>
          </MenuItem>
          <Divider className={classes.divider} />
        </React.Fragment>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <Container maxWidth="xl" disableGutters>
        <AppBar position={"fixed"} className={classes.header}>
          <Toolbar className="header-desktop">
            <NavLink className={"logo-link"} to={"/"}>
              <img className="logo" src={Logo} alt="logo" />
            </NavLink>

            {!isLoged ? (
              <div className="login-buttons-desktop">
                <Link
                  className={`${classes.title} menu-item  menu-item-button`}
                  href={"/register"}
                >
                  <Button className={classes.menuButton} color="inherit">
                    Registrieren
                  </Button>
                </Link>
                <Link
                  className={`${classes.title} menu-item last-ele menu-item-button`}
                  href={"/login"}
                >
                  <Button className={classes.menuButtonLogin} color="inherit">
                    Anmelden
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="login-buttons">
                <a className="menu-user" href="/dashboard">
                  <IconButton aria-haspopup="true" className={"account-icon"}>
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </IconButton>
                </a>
                <IconButton
                  aria-haspopup="true"
                  onClick={recordButtonPosition}
                  className={classes.circlIcon}
                >
                  <ArrowDropDownIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={closeMenu}
                >
                  <MenuItem className={classes.profile}>
                    <Link href="/dashboard">Meine Kurse</Link>
                  </MenuItem>
                  <MenuItem className={classes.profile}>
                    <Link href={learner_profile}>Profil</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href={account_settings}>Benutzerkonto</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href={logout}>Abmelden</Link>
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
          <Toolbar className="second-row-header header-desktop">
            <div className="header-modal">
              <button
                className={classes.menuModalButton}
                onClick={openModal}
                color="inherit"
              >
                <span className="text"> Fragen? Chatten Sie mit uns! </span>{" "}
                <span
                  className="msg-icon fa fa-comment"
                  aria-hidden="true"
                ></span>
              </button>
              <Menu
                id="menu-chatbot"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                // anchorOrigin={{ vertical: "left", horizontal: "left" }}
                open={modalEl}
                onClose={handleClose}
                className={classes.menu}
              >
                <div className="modal-body-header">
                  <header>ETC Chat Service</header>
                  <div>
                    Hallo bei ETC! Derzeit sind wir im Chat nicht online - aber
                    hinterlassen Sie uns hier eine Nachricht und wir melden uns
                    schnellstens bei Ihnen!
                    <form action="">
                      <label>Kontaktdetails *</label>
                      <input type="text" />
                      <label>Telefonnummer</label>
                      <input type="text" />
                      <label>Nachricht *</label>
                      <textarea></textarea>
                      <input
                        className="submit-button"
                        type="submit"
                        value="Nachricht senden"
                      />
                    </form>
                  </div>
                </div>
              </Menu>
            </div>
            <div className="header-tabs" id="header-tabs">
              <ul className="nav nav-tabs nav-justified" role="tablist">
                <li className="nav-item active-item">
                  <Link
                    className="nav-item-link active"
                    data-toggle="tab"
                    href="/"
                    role="tab"
                    aria-controls="seminarangebot"
                    aria-selected="true"
                  >
                    Seminarangebot
                  </Link>
                </li>
                {!isLoged ? (
                  <React.Fragment />
                ) : (
                  <li className="nav-item">
                    <Link
                      className="nav-item-link"
                      data-toggle="tab"
                      href="/dashboard"
                      role="tab"
                      aria-controls="Meine Kurse"
                      aria-selected="true"
                    >
                      Meine Kurse
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </Toolbar>
          <div className="header-mobile">
            <NavLink to={"/"} className="logo-link-mobile active">
              <img className="logo-mobile" src={Logo} alt="logo" />
            </NavLink>
            <div
              className={`hamburger-menu ${toggleMenu}`}
              role="button"
              aria-label="Menüoptionen"
              aria-expanded="false"
              aria-controls="mobile-menu"
              onClick={toggle}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
          <div className={toggleState}>{drawer}</div>

          <Toolbar className="second-row-header header-mobile">
            <div className="header-modal">
              <Button
                id="modal-button"
                className={classes.menuButtonLogin}
                onClick={openModal}
                color="inherit"
              >
                <span
                  className="msg-icon fa fa-comment"
                  aria-hidden="true"
                ></span>
              </Button>
              <Menu
                id="menu-chatbot"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={modalEl}
                onClose={handleClose}
                className={classes.menu}
              >
                <div className="modal-body-header">
                  <header>ETC Chat Service</header>
                  <div>
                    Hallo bei ETC! Derzeit sind wir im Chat nicht online - aber
                    hinterlassen Sie uns hier eine Nachricht und wir melden uns
                    schnellstens bei Ihnen!
                    <form action="">
                      <label>Kontaktdetails *</label>
                      <input type="text" />
                      <label>Telefonnummer</label>
                      <input type="text" />
                      <label>Nachricht *</label>
                      <textarea></textarea>
                      <input
                        className="submit-button"
                        type="submit"
                        value="Nachricht senden"
                      />
                    </form>
                  </div>
                </div>
              </Menu>
            </div>
            <div className="header-tabs" id="header-tabs">
              <ul className="nav nav-tabs nav-justified" role="tablist">
                <li className="nav-item active-item">
                  <Link
                    className="nav-item-link active"
                    data-toggle="tab"
                    href="/"
                    role="tab"
                    aria-controls="seminarangebot"
                    aria-selected="true"
                  >
                    Seminarangebot
                  </Link>
                </li>
                {!isLoged ? (
                  <React.Fragment />
                ) : (
                  <li className="nav-item">
                    <Link
                      className="nav-item-link"
                      data-toggle="tab"
                      href="/dashboard"
                      role="tab"
                      aria-controls="Meine Kurse"
                      aria-selected="true"
                    >
                      Meine Kurse
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </Toolbar>
        </AppBar>
      </Container>
    </React.Fragment>
  );
}
